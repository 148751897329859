<template>
  <div class="rankList_warp">
    <div class="dropdown">
      <el-dropdown @command="handleRankCommand">
          <span class="el-dropdown-link weight-600 text-16">
            {{rankName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="item"
                            v-for="item in rankList"
                            :key="item.valye"
                            :command="item.value"
          >{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown @command="handleDayCommand">
          <span class="el-dropdown-link pl-20 text-16">
            {{dayName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="item"
                            v-for="item in dayList"
                            :key="item.valye"
                            :command="item.value"
          >{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="rankList">
      <div class="rankList_item" v-for="(item,i) in dataListSlice" :key="i">
        <div class="label">{{i+1}}</div>
        <div class="name">{{ item.staffName }}</div>
        <div class="value">{{ item.count }}</div>
      </div>
    </div>
    <div class="button">
      <ch-button type="ok" @click="watchAll">查看全部</ch-button>
    </div>
    <watch-rank ref="watchRank" />
  </div>
</template>
<script>
import watchRank from './watchRank.vue'
export default {
  components:{
    watchRank
  },
  data(){
    return {
      rankList:[
        { name: '交易排行榜', value: 1},
        { name: '利润排行榜', value: 2},
        { name: '收入排行榜', value: 3},
        { name: '服务人员排名', value: 4},
        { name: '点击量排行榜', value: 5},
        { name: '社区排行榜', value: 6},
      ],
      dayList: [
        { name: "今日", value: 1, },
        { name: "昨天", value: 2, },
        { name: "近7天", value: 3, },
        { name: "近1个月", value: 4, },
      ],
      dayName:'近1个月',
      rankName:'服务人员排名',
      dayType: 4,
      rankType: 4,
      dataList:[],
      dataListSlice:[],
    }
  },
  created() {
    this.getDateList()
  },
  methods:{
    handleRankCommand(command){
      let rank = this.rankList.find((item) => item.value === command)
      this.rankName = rank.name
      this.rankType = rank.value
      this.getDateList()
    },
    handleDayCommand(command) {
      let day = this.dayList.find((item) => item.value === command)
      this.dayName = day.name
      this.dayType = day.value
      this.getDateList()
    },
    getDateList(){
      if(this.rankType === 4){
        this.getStaffRanking()
      }
    },
    async getStaffRanking(){
      this.$curl.get('/hm/homePage/staffRanking/'+this.dayType).then(res=>{
        this.dataListSlice = res.data.slice(0,5) // 取排名前五
        this.dataList = res.data.map((item,i)=>{
          return {
            ...item,
            index: i+1
          }
        })
      })
    },
    watchAll(){
      this.$refs.watchRank.openWatch(this.dataList,'服务人员排名')
    },
  }
}
</script>
<style scoped lang="scss">
.el-dropdown-menu__item {
  padding: 0 18px 0 10px;
}
.dropdown {
  padding-top: 20px;
  padding-left: 20px
}
.rankList_warp {
  background: #fff;
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rankList {
  padding: 0 25px;
  flex: 1;
  overflow: auto;
  .rankList_item{
    display: flex;
    padding: 10px 0;
    color: #666666;
    &:nth-child(1) .label{
      background: #157AFE;
      color: #FFFFFF;
    }
    &:nth-child(2) .label{
      background: #FF770A;
      color: #FFFFFF;
    }
    &:nth-child(3) .label{
      background: #FF3C5F;
      color: #FFFFFF;
    }
    .label{
      background: #F0F2F5;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      text-align: center;
      line-height: 26px;
      margin-right: 30px;
      color: rgba(0, 0, 0, 0.65);
    }
    .name{
      flex: 1;
      font-size: 14px;
    }
  }
}
.button{
  text-align: center;
  position: sticky;
  bottom: 6px;
  left: 0;
  right: 0;
  z-index: 100;
  background: #FFFFFF;
}

</style>